import { windowCacheDeleteCb } from '@shein/common-function'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

if ('serviceWorker' in navigator && typeof fetch !== 'undefined') {
  onWindowLoad(() => {
    requestUserIdle(async () => {
      const { langPath } = gbCommonInfo
      const swFile = window.TPM?.marketings?.WebPush?.active === true
        ? '/aimtell-worker.js'
        : langPath + '/sw-runtime-cache.js'
      try {
        const registration = await navigator.serviceWorker.register(swFile)
        window.dispatchEvent(new Event('SW_Loaded'))
        window.SW_Loaded = true
        console.log('ServiceWorker 注册成功。', registration)
      } catch (error) {
        console.error('ServiceWorker 注册失败。', error)
      }
    })
  })
}

// 页面访问时清除sw缓存
(() => {
    const swCacheClearConfig = {
      '/user/auth/login': ['pcMeHtml']
    }

    Object.keys(swCacheClearConfig).forEach((key) => {
      if(location.pathname.includes(key)) {
        windowCacheDeleteCb({ key: swCacheClearConfig[key] })
      }
    })
})()
